<template>
  <div v-if="av" class="card-details-wrapper">
    <p class="name">{{ object.name }}</p>
    <p>{{ object.description }}</p>
    <ul>
      <price :object="object" :location="defaultStock.location" />
      <availability :av="av" />
      <li>
        <div class="btns">
          <b-input-group>
            <div class="quantity">
              <button @click="updateQuantity('minus')">-</button>
              <span>{{ quantity }}</span>
              <button @click="updateQuantity('plus')">+</button>
            </div>
            <b-button
              v-if="av.case !== 'outofstock'"
              variant="primary"
              class="button"
              :disabled="!cookiesConsent.accepted"
              @click="addToCart"
            >
              <v-icon dark left>mdi-cart-arrow-down</v-icon>
              {{
                cookiesConsent.accepted
                  ? $t("addToCart")
                  : $t("noCookieConsent")
              }}
            </b-button>
            <b-button v-else class="button" @click.stop="showEnquiry = true">
              <v-icon dark left>mdi-cart-arrow-down</v-icon>
              {{ $t("sendEnquiry") }}
            </b-button>
          </b-input-group>
        </div>
      </li>
    </ul>

    <!-- SNACKBAR -->
    <v-snackbar v-model="snackbar" top left :timeout="timeout">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">{{ $t("COMMON.CLOSE") }}</v-btn>
    </v-snackbar>

    <!-- ENQUIRY -->
    <enquiry
      :dialog="showEnquiry"
      :part="object.name"
      :manufacturer="object.manufacturer"
      @snack="
        () => {
          snackbarText = $t('DETAILS.ENQUIRY_SENT');
          snackbar = true;
        }
      "
      @modal="
        val => {
          showEnquiry = val;
        }
      "
    />
  </div>
</template>

<script>
import axios3C from "./../../axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Price from "./Price";
import Availability from "./Availability";
import Enquiry from "./Enquiry";
import { MANUFACTURER, DBA_EXCLUDED } from "./../../vars";
import api from "@/api2";
import { getAvailability } from "../../availability";

export default {
  name: "CardDetailsComponent",
  components: { Enquiry, Availability, Price },
  props: {
    object: Object,
    disableButton: Boolean,
    config: Object
  },
  computed: {
    ...mapState(["cart_id", "cookiesConsent", "userCountry", "currency"]),
    ...mapGetters(["getBasketCount"]),
    available() {
      return this.object.available;
    },
    additionalservices() {
      return this.object.price.prices[this.defaultStock.location]
        .additionalservices;
    },
    deliveries() {
      return this.object.deliveries;
    },

    disableButtonQuantity() {
      return this.quantityInBasket >= this.warehouseAvl + this.manufacturerAvl;
    },
    productType() {
      let ans = "other";
      const name = this.object && this.object.name;
      const excluded = DBA_EXCLUDED;

      if (name) {
        if (excluded.includes(name)) {
          ans = "excluded";
        } else if (
          name.includes("DBA") &&
          (name.includes("SP") || name.includes("XP"))
        ) {
          ans = "kit";
        } else if (name.includes("DBA")) {
          ans = "discbrake";
        } else if (name.includes("DB")) {
          ans = "brakepad";
        }
      }

      return ans;
    },
    manufacturer() {
      return Object.keys(MANUFACTURER).find(
        key => MANUFACTURER[key] === this.object.manufacturergroup
      );
    }
  },
  async mounted() {
    this.setQuantityInBasket();
    this.setStocks();
    this.setAvailability();
  },
  methods: {
    ...mapMutations(["setCartId"]),
    handleChange() {
      this.setAvailability();
    },
    updateQuantity(option) {
      if (option === "plus" && this.quantity < this.max) {
        this.quantity = this.quantity + 1;
      } else if (option === "minus" && this.quantity > 1) {
        this.quantity = this.quantity - 1;
      } else {
        return;
      }
    },
    setQuantityInBasket() {
      if (this.cart_id) {
        api.getCarts(this.cart_id, this.axios3C, this.currency).then(res => {
          const items = res.data.shoppingcarts[0].shoppingcart.transactionlines;
          const item = items.find(e => e.item._id === this.object.id);
          this.quantityInBasket = item.quantity;
        });
      }
    },
    setStocks() {
      this.defaultStock = this.available.find(e => e.default);
      this.dropshipStock = this.available.find(e => e.dropship);
      this.manufacturerStock = this.available.find(
        e => e.location === "manufacturer"
      );
    },
    setAvailability() {
      const quantity = this.quantity + this.quantityInBasket;

      const { av, max, ctx } = getAvailability(
        this.object,
        quantity,
        this.quantityInBasket,
        this.manufacturer
      );
      this.av = av;
      this.max = max;
      this.ctx = ctx;
    },
    addToCart() {
      this.disableButtonInner = true;

      let payload = {
        action: "addToCart",
        quantity: this.quantity,
        record: this.object.id,
        location: this.defaultStock.location,
        additionalservice:
          this.av.case === "airfreight" && this.additionalservices.length
            ? this.additionalservices[0]._id
            : [],
        customer: "guest",
        currency: this.currency,
        country: this.userCountry
      };
      if (this.cart_id) {
        payload = { ...payload, cart_id: this.cart_id };
      }
      this.axios3C
        .post("/cart", payload)
        .then(res => {
          this.$store.commit(
            "updateQuantity",
            this.quantity + this.getBasketCount
          );
          if (!this.cart_id) {
            this.setCartId(res.data.cart_id);
          }
          this.snackbar = true;
        })
        .catch(err => {
          this.$emit("cartError", err);
        })
        .finally(() => {
          this.disableButtonInner = false;
          this.quantityInBasket = this.quantityInBasket + this.quantity;
          this.quantity = 1;
        });
    }
  },
  watch: {
    quantity() {
      if (this.quantity > this.max) {
        this.quantity = 1;
      }
      this.$emit("quantityChanged", this.quantity);
      this.handleChange();
    },
    quantityInBasket: "handleChange"
  },
  data: function() {
    return {
      snackbarText: this.$t("DETAILS.ITEM_ADDED"),
      showEnquiry: false,
      axios3C,
      disableButtonInner: false,
      timeout: 2000,
      snackbar: false,
      showAdditional: false,
      quantity: 1,
      quantityInBasket: 0,
      defaultStock: "",
      dropshipStock: "",
      manufacturerStock: "",
      max: 0,
      av: null,
      ctx: null
    };
  }
};
</script>

<style lang="scss" scoped>
.input-locations,
.services {
  text-align: left;

  .service {
    font-size: 14px;
  }

  div {
    margin-top: 0;
  }

  small {
    padding-left: 35px;
  }
}

.air {
  .air-btn {
    font-family: "Michroma", sans-serif;
    background: url("./../../assets/green.png");
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    font-size: 0.8em;
    height: 75px;
    color: white;
    font-weight: 700;
    word-spacing: 5px;
    padding: 20px 20px 0 50px;

    &-close {
      padding-top: 30px;
    }
  }

  .air-btn-active {
    box-shadow: 0 0 5px 5px var(--red);
  }
}

.card-details-wrapper {
  color: black;

  ul {
    padding: 0;
    margin: 10px 0;
    list-style-type: none;
    border: 1px solid darkgray;
    li {
      border: 1px solid lightgray;
      .li-flex {
        display: flex;
        justify-content: space-between;
        text-align: left;

        div:last-of-type {
          text-align: right;
        }
      }
      padding: 10px;
      &:last-of-type {
        border-bottom: 1px solid darkgray;
      }
    }
  }
}
</style>

<style lang="scss">
.form-control {
  border-left: 1px solid darkgray;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
}

.service {
  .v-label {
    font-size: 14px;
  }
}

.input-locations,
.services {
  .v-label {
    margin-bottom: 0;
    color: black;

    &:first-of-type {
      margin-right: 10px;
    }
  }
}

.btns {
  display: flex;
}

.button {
  flex: 4;
  font-size: 14px !important;
}

.quantity {
  height: 38px;
  display: flex;
  max-width: 120px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid darkgray;
  flex: 1;
  button {
    padding: 0 15px;
    font-size: 22px;
  }
}
</style>
